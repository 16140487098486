import { IconButton } from "components/Button";
import { default as store } from "store/configureStore.js";
import { AuthProvider } from "react-oidc-context";
import { Provider as StoreProvider } from "react-redux";
import { RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import "moment/locale/pl";
import { ConfigProvider, UserProvider, VersionProvider } from "context";
import { SnackbarProvider, closeSnackbar } from "notistack";
import { userManager, userManagerConfig } from "utils-ts/auth/userManager";
import createRoutes from "routing-ts/createRoutes";
import { ApiProvider } from "components-ts/providers";
import "./i18n";
import theme from "./theme.js";

moment.locale("pl");
moment.updateLocale("pl", {
    week: {
        dow: 1,
    },
});

const router = createBrowserRouter(createRoutes());

function App() {
    return (
        <ConfigProvider>
            <StoreProvider store={store}>
                <AuthProvider
                    {...userManagerConfig}
                    userManager={userManager}
                    skipSigninCallback={true}
                >
                    <UserProvider>
                        <VersionProvider>
                            <ApiProvider>
                                <SnackbarProvider
                                    maxSnack={5}
                                    dense
                                    action={(snackbarId) => (
                                        <IconButton
                                            color="#fff"
                                            icon="delete"
                                            onClick={() => {
                                                closeSnackbar(snackbarId);
                                            }}
                                        />
                                    )}
                                >
                                    <ThemeProvider theme={theme}>
                                        <LocalizationProvider
                                            dateAdapter={AdapterMoment}
                                            adapterLocale="pl"
                                        >
                                            <RouterProvider router={router} />
                                        </LocalizationProvider>
                                    </ThemeProvider>
                                </SnackbarProvider>
                            </ApiProvider>
                        </VersionProvider>
                    </UserProvider>
                </AuthProvider>
            </StoreProvider>
        </ConfigProvider>
    );
}

export default App;
