import { FieldPath, FieldValues } from "react-hook-form";
import { FormControlProps } from "./types";
import { AutocompleteProps } from "control-types";
import { Autocomplete } from "components-ts/controls";
import { default as Controller } from "./Controller";

export type FormAutocompleteProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = FormControlProps<string[], TFieldValues, TName> &
    Omit<AutocompleteProps, "textFieldProps" | "onChange"> & {
        onCustomChange?: (value: string | number | string[] | number[] | undefined) => void;
    };

const FormAutocomplete = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
    name,
    label,
    defaultValue,
    items,
    disableCloseOnSelect,
    multiple,
    freeSolo,
    selectOnFocus,
    clearOnBlur,
    emptyValue,
    width,
    readOnly,
    useVirtualization,
    filterOptions,
    groupBy,
    onCustomChange,
}: FormAutocompleteProps<TFieldValues, TName>): JSX.Element => {
    return (
        <Controller
            name={name}
            render={({ field: { ref, value, onChange, disabled: _, ...fieldProps }, fieldState: { error } }) => {
                return (
                    <Autocomplete
                        ref={ref}
                        {...fieldProps}
                        label={label}
                        error={{
                            hasError: Boolean(error),
                            message: error?.message,
                        }}
                        onChange={(value) => {
                            onChange(value);
                            if (onCustomChange) {
                                onCustomChange(value);
                            }
                        }}
                        items={items}
                        multiple={multiple}
                        disableCloseOnSelect={disableCloseOnSelect}
                        freeSolo={freeSolo}
                        selectOnFocus={selectOnFocus}
                        clearOnBlur={clearOnBlur}
                        value={value}
                        useVirtualization={useVirtualization}
                        readOnly={readOnly}
                        filterOptions={filterOptions}
                        groupBy={groupBy}
                    />
                );
            }}
            defaultValue={defaultValue}
            transform={(val) => (val ? val : emptyValue)}
            width={width}
        />
    );
};

export default FormAutocomplete;
